import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Brick       from 'app/components/common/brick';
import config      from 'app/config';
import {
  VolEventTypes,
}                  from 'app/constants';
import format      from 'app/helpers/format';
import helpers     from 'app/helpers/vol-events';
import paths       from 'app/paths';
import EntitiesSlx from 'app/selectors/entities';

class VolEventCard extends React.PureComponent {

  render() {
    const { volEvent } = this.props;
    const company = volEvent.isSystemEvent ? null : volEvent.company;
    const imgUrl = volEvent.imgPath ? `${config.cdnBaseUrl}/${volEvent.imgPath}` : null;
    const isEvent = volEvent.type === VolEventTypes.EVENT;
    const location = volEvent.isRemote
      ? 'Remote'
      : [volEvent.city, volEvent.state].filter(p => p).join(', ');
    const cta = volEvent.isSystemEvent
      ? 'Details'
      : isEvent ? 'Join' : 'Volunteer';
    const badge = isEvent ? 'Event' : 'Volunteer';
    const color = isEvent ? 'groups' : 'orange';

    return (
      <Brick
        imgUrl={imgUrl}
        href={paths.volEvent(volEvent.id)}
        title={volEvent.title}
        description={volEvent.description}
        cta={cta}
        badge={badge}
        details={[helpers.getDateLabel(volEvent), location]}
        color={color}
        companyLogoUrl={company?.logoUrl}
      />
    );
  }

}

VolEventCard.propTypes = {
  id: PropTypes.string,
  volEvent: PropTypes.object.isRequired,
};

VolEventCard.defaultProps = {};

const stateToProps = (state, ownProps) => ({
  volEvent: ownProps.volEvent || EntitiesSlx.volEventById(state, ownProps.id),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(VolEventCard);
