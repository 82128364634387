import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import backstageApi from 'app/apis/backstage';
import Modal from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import StandardSelect from 'app/components/common/standard-select';
import TimezoneInput from 'app/components/common/timezone-input';
import paths from 'app/paths';

import Checkbox from 'app/components/common/checkbox';
import {
  Features,
  CompanyCustomerTypes as CustomerTypes,
} from 'app/constants';

const custTypeNames = {
  [CustomerTypes.CUSTOMER]: 'Customer',
  [CustomerTypes.FRIENDS]: 'Friends & Family',
  [CustomerTypes.TEST]: 'Testing/Dev',
};
const custTypeOpts = Object.values(CustomerTypes).map((ct) => {
  const name = custTypeNames[ct] || ct;
  return {value: ct, label: name};
});

class ModalEditCompany extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      submitPending: false,
      timezone: null,
      name: null,
      saasWhitelist: null,
      year2022StartDateStr: null,
      saasEmployeeCap: null,
      customerType: null,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onChangeTimezone = this.onChangeTimezone.bind(this);
    this.onChangeCustomerType = this.onChangeCustomerType.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmployeeCap = this.onChangeEmployeeCap.bind(this);
    this.onChangeYear2020StartDate = this.onChangeYear2020StartDate.bind(this);
  }

  get isNew() {
    return !this.props.company;
  }

  get name() {
    if (this.state.name != null) return this.state.name;
    if (this.props.company) return this.props.company.name;
    return null;
  }

  get customerType() {
    if (this.state.customerType != null) return this.state.customerType;
    if (this.props.company) return this.props.company.customerType;
    return CustomerTypes.CUSTOMER;
  }

  get saasWhitelist() {
    if (this.state.saasWhitelist) return this.state.saasWhitelist;
    if (this.props.company) return this.props.company.saasWhitelist;
    return [];
  }

  get year2022StartDateStr() {
    if (this.state.year2022StartDateStr != null) return this.state.year2022StartDateStr;
    if (this.props.company) return this.props.company.year2020StartDate;
    return '2020-01-01';
  }

  get timezone() {
    if (this.state.timezone) return this.state.timezone;
    if (this.props.company) return this.props.company.timezone;
    return null;
  }

  get saasEmployeeCap() {
    if (this.state.saasEmployeeCap != null) return this.state.saasEmployeeCap;
    if (this.props.company) return this.props.company.saasEmployeeCap;
    return 0;
  }

  get attrs() {
    const attrs = {
      name: (this.name || '').trim(),
      saasWhitelist: this.saasWhitelist,
      saasEmployeeCap: parseInt(this.saasEmployeeCap),
      timezone: this.timezone,
      customerType: this.customerType,
      year2020StartDate: this.year2022StartDateStr,
    };
    if (this.isNew) {
      attrs.slug = attrs.name.replace(/\W+/g, '-').toLowerCase();
    }
    return attrs;
  }

  get isValid() {
    if (!this.timezone) return false;
    if (!this.saasWhitelist) return false;
    if (!(this.name || '').trim()) return false;
    if (!this.year2022StartDateStr.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) return false;
    if (!moment(this.year2022StartDateStr).isValid()) return false;
    if (!_.isFinite(parseInt(this.saasEmployeeCap))) return false;
    return true;
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickSubmit() {
    console.log(this.attrs);
    this.setState({submitPending: true});
    const promise = this.isNew
      ? backstageApi.companiesCreate(this.attrs)
      : backstageApi.companiesUpdate(this.props.company.id, this.attrs);
    promise
      .then(({id}) => {
        const path = paths.bsCompany(id);
        window.location.href = path;
      })
      .catch((error) => {
        alert('Oops! Something went wrong.');
      });
  }

  onChangeFeature(feature, event) {
    const fSet = new Set(this.saasWhitelist);
    const isOn = event.target.checked;
    if (isOn) {
      fSet.add(feature);
    } else {
      fSet.delete(feature);
    }
    const saasWhitelist = [...fSet];
    this.setState({saasWhitelist});
  }

  onChangeName(event) {
    const name = event.target.value;
    this.setState({name});
  }

  onChangeEmployeeCap(event) {
    const saasEmployeeCap = event.target.value;
    this.setState({saasEmployeeCap});
  }

  onChangeYear2020StartDate(event) {
    const year2022StartDateStr = event.target.value;
    this.setState({year2022StartDateStr});
  }

  onChangeTimezone(timezone) {
    this.setState({timezone});
  }

  onChangeCustomerType(customerType) {
    this.setState({customerType});
  }

  render() {
    const { submitPending } = this.state;
    const buttonText = submitPending
      ? this.isNew ? 'Creating...' : 'Updating...'
      : this.isNew ? 'Create' : 'Update';
    const disabled = submitPending || !this.isValid;

    return (
      <Modal className="bform bs-modal-comp" onClose={this.onCloseModal}>
        <h1 className="bform-h1">{this.isNew ? 'New' : 'Edit'} Company</h1>

        <label className="bform-h3">Name</label>
        <StandardInput name="name" label="Name" value={this.name || ''} onChange={this.onChangeName} />

        <label className="bform-h3">First Day of 2020 (YYYY-MM-DD)</label>
        <StandardInput name="year2020StartDate" label="First Day of 2020 (YYYY-MM-DD)" value={this.year2022StartDateStr || ''} onChange={this.onChangeYear2020StartDate} />

        <label className="bform-h3">Timezone</label>
        <TimezoneInput onChange={this.onChangeTimezone} timezone={this.timezone} />

        <label className="bform-h3">Customer Type</label>
        <StandardSelect onSelect={this.onChangeCustomerType} value={this.customerType} options={custTypeOpts} />
        <p className="bform-input-desc">Mostly for internal purposes. Test-type companies' employees will be excluded from Mailchimp list. May have more uses in the future.</p>

        <label className="bform-h3">Employee Cap</label>
        <StandardInput name="saasEmployeeCap" label="Employee Cap" value={this.saasEmployeeCap || ''} onChange={this.onChangeEmployeeCap} type="number" />

        <label className="bform-h3">Features</label><br />
        <div className="bs-modal-comp-features">
          <div className="bs-modal-comp-features-selection">
            {Object.values(Features).map((feature) => {
              const checked = this.saasWhitelist.includes(feature);
              return (
                <div key={feature} className="bs-modal-comp-features-selection-feature">
                  <Checkbox id={`cb-feature-${feature}`} checked={checked} onChange={this.onChangeFeature.bind(this, feature)} />
                  <label htmlFor={`cb-feature-${feature}`}>{feature}</label>
                </div>
              );
            })}
          </div>
        </div>


        <div className="bform-actions">
          <button className="btn blue" onClick={this.onClickSubmit} disabled={disabled}>{buttonText}</button>
        </div>
      </Modal>
    );
  }

}

ModalEditCompany.propTypes = {
  onClose: PropTypes.func.isRequired,
  company: PropTypes.object,
};

export default ModalEditCompany;
