import PropTypes from 'prop-types';
import React from 'react';

import Modal  from 'app/components/common/modal';
import config from 'app/config';

class ImageModal extends React.PureComponent {

  render() {
    const { imgPath, className, onClose, children } = this.props;
    const imgUrl = `${config.cdnBaseUrl}/${imgPath}`;
    const isPdf = imgUrl.toLowerCase().endsWith('.pdf');

    return (
      <Modal className={`image-modal ${className}`} onClose={onClose}>
        {children}
        {isPdf && (<>
          <a className="btn small" href={imgUrl} target="_blank">Download</a>
          <br /><br />
        </>)}
        {isPdf
          ? <iframe src={imgUrl} className="image-modal-iframe" />
          : <img src={imgUrl} className="image-modal-img" />
        }
      </Modal>
    );
  }

}

ImageModal.propTypes = {
  className: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  imgPath: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ImageModal.defaultProps = {
  className: '',
};

export default ImageModal;
