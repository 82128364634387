import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import FakeLines    from 'app/components/common/fake-lines';
import Icon         from 'app/components/common/icon';
import Link         from 'app/components/common/link';
import RecentDonors from 'app/components/common/recent-donors';
import MainLayout   from 'app/components/layout/main-layout';
import Ctas         from 'app/components/nonprofit-profile/ctas';
import Donate       from 'app/components/nonprofit-profile/donate';
import BadgeList    from 'app/components/nonprofits/badge-list';
import config       from 'app/config';
import {
  Categories,
  CategoryNames,
  USER_DEFAULT_AVATAR_URL,
}                   from 'app/constants';
import cdn          from 'app/helpers/cdn';
import countries    from 'app/helpers/countries';
import utils        from 'app/helpers/utils';
import paths        from 'app/paths';

const renderParagraph = (str) => {
  return (str || '').split('\n').filter(p => p).map((part, i) => (
    <p key={i}>{part}</p>
  ));
}

class NonprofitProfile extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refRoot = React.createRef();

    this.onClickDonateCta = this.onClickDonateCta.bind(this);
  }

  get name() {
    const {nonprofit, profile, preferProfile} = this.props;
    return preferProfile ? profile.name : nonprofit.name;
  }

  get mission() {
    const {nonprofit, profile, preferProfile} = this.props;
    return preferProfile ? profile.mission : nonprofit.mission;
  }

  get nteeObj() {
    const {nonprofit, profile, preferProfile} = this.props;
    return preferProfile ? (profile.nteeObj || nonprofit.nteeObj) : nonprofit.nteeObj;
  }

  get location() {
    const {nonprofit, profile, preferProfile} = this.props;
    const isUs = nonprofit.countryCode === 'US';
    const [city, state] = (() => {
      if (preferProfile && profile.address) return [profile.address.city, profile.address.state];
      return [nonprofit.city, nonprofit.state];
    })();
    let str = [city, state].filter(p => p).join(', ');
    if (!isUs && this.country) {
      str += `, ${this.country.code} ${this.country.flag}`;
    }
    return str;
  }

  get country() {
    const {nonprofit} = this.props;
    if (!nonprofit?.countryCode) return null;
    return countries.byCode[nonprofit.countryCode] || null;
  }

  onClickDonateCta() {
    const rootEl = this.refRoot.current;
    const donateEl = rootEl.querySelector('.donate');
    if (donateEl) {
      donateEl.scrollIntoView({behavior: 'smooth'});
    }
  }

  renderHero() {
    const { profile, editMode } = this.props;
    if (!profile) return null;
    const isComplete = !!(profile.img1Path && profile.img2Path && profile.img3Path);
    if (!isComplete && !editMode) return null;
    const showLogo = !!(profile.logoPath || editMode);
    const img1Url = profile.img1Path ? cdn.imgUrl(profile.img1Path, {width:  935}) : '/images/npp/placeholder-main-photo.png';
    const img2Url = profile.img2Path ? cdn.imgUrl(profile.img2Path, {width: 1144}) : '/images/npp/placeholder-main-photo.png';
    const img3Url = profile.img3Path ? cdn.imgUrl(profile.img3Path, {width: 1122}) : '/images/npp/placeholder-main-photo.png';
    const logoUrl = profile.logoPath ? cdn.imgUrl(profile.logoPath, {width:  418}) : '/images/npp/placeholder-logo.png';

    return (
      <div className="npp-section npp-hero">
        <div className="npp-hero-heighter">
          <div className="npp-hero-image-con ic1">
            <div style={{backgroundImage: `url("${img1Url}")`}} className="npp-hero-image" />
          </div>
          <div className="npp-hero-image-con ic2">
            <div style={{backgroundImage: `url("${img2Url}")`}} className="npp-hero-image" />
          </div>
          <div className="npp-hero-image-con ic3">
            <div style={{backgroundImage: `url("${img3Url}")`}} className="npp-hero-image" />
          </div>
          {showLogo &&
            <img src={logoUrl} className="npp-hero-logo" />
          }
        </div>
      </div>
    );
  }

  renderBasic() {
    const { nonprofit, profile, editMode, bracketView } = this.props;
    const { ein, badges, registeredNumber, registry } = nonprofit;
    const registryName = registry?.englishName || null;
    const { tags } = this.nteeObj || {};
    const hasTags = !!(tags && tags.length);
    const regNum = ein
      ? `EIN: ${ein}`
      : registryName ? `${registryName} Number: ${registeredNumber}` : registeredNumber;
    const showCtas = !editMode && !bracketView;

    return (
      <div className="npp-section npp-basic">
        {(!this.name && editMode) ? (
          <FakeLines count={1} lineHeight={48} />
        ) : (
          <h1>{this.name}</h1>
        )}
        <BadgeList badges={badges} className="npp-basic-badges" />
        {showCtas && (
          <Ctas nonprofit={nonprofit} onClickDonate={this.onClickDonateCta} />
        )}
        <div className="npp-basic-mission">
          {(!this.mission && editMode) ? (
            <FakeLines count={2} />
          ) : (
            this.mission ? renderParagraph(this.mission) : <p />
          )}
        </div>
        <div className="npp-basic-cols npp-metadata">
          <div className="npp-basic-cols-left">
            {this.location &&
              <div className="npp-basic-location">
                <Icon.Pin />
                <span>{this.location}</span>
              </div>
            }
            <div className="npp-basic-ein">{regNum}</div>
          </div>
          {hasTags && (<>
            <div className="npp-basic-cols-sep" />
            <div className="npp-basic-cols-right">
              <div className="npp-basic-tags">
                {tags.map((tag) => (
                  <div className="npp-basic-tags-tag" key={tag}>{tag}</div>
                ))}
              </div>
            </div>
          </>)}
        </div>
      </div>
    );
  }

  renderRecentDonors() {
    const { nonprofit } = this.props;
    if (!nonprofit) return null;
    const { donorCount, recentDonors } = nonprofit;
    return (
      <RecentDonors
        className="npp-recent-donors"
        donorCount={donorCount}
        recentDonors={recentDonors}
      />
    );
  }

  renderPersonal() {
    const { profile, editMode } = this.props;
    if (!profile) return null;
    const { personalText, personalImgPath } = profile;
    const isComplete = !!(personalText && personalImgPath);
    const isPartial = !!(personalText || personalImgPath);
    if (!editMode && !isComplete) return null;
    const showFake = editMode && !isPartial;
    const imgUrl = showFake
      ? '/images/npp/placeholder-program.png'
      : (personalImgPath && `${config.cdnBaseUrl}/${personalImgPath}`);
    return (
      <div className="npp-section npp-personal">
        <div className="npp-personal-text">
          {showFake ? <FakeLines count={5} lineHeight={9} /> : renderParagraph(personalText)}
        </div>
        {imgUrl &&
          <div className="npp-personal-image" style={{backgroundImage: `url("${imgUrl}")`}} />
        }
      </div>
    );
  }

  renderStats() {
    const { profile, editMode } = this.props;
    if (!profile) return null;
    const { stats } = profile;
    if (!stats || !stats.length) return null;

    return (
      <div className="npp-section npp-stats">
        {stats.map((stat, i) => {
          const isComplete = !!(stat.figure && stat.text);
          const isPartial = !!(stat.figure || stat.text);
          if (!editMode && !isComplete) return null;
          const showFake = editMode && !isPartial;
          const fakeClass = showFake ? 'fake' : '';
          return (
            <div className={`npp-stats-stat ${fakeClass}`} key={i}>
              <div className="npp-stats-stat-number">{showFake ? <FakeLines count={1} lineHeight={72} style="full" /> : stat.figure}</div>
              <div className="npp-stats-stat-text">{showFake ? <FakeLines count={2} /> : stat.text}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderVideo() {
    const { profile, editMode } = this.props;
    if (!profile) return null;
    const { youtubeId } = profile;
    if (!youtubeId && !editMode) return null;
    return (
      <div className="npp-section npp-video">
        <div className="npp-video-heighter">
          {youtubeId ? (
            <iframe src={`https://www.youtube.com/embed/${youtubeId}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          ) : (
            <img src="/images/npp/placeholder-video.png" alt="video placeholder" />
          )}
        </div>
      </div>
    );
  }

  renderDollarStrength() {
    const { profile, editMode } = this.props;
    if (!profile) return null;
    const { dollarStrengths } = profile;
    if (!dollarStrengths || !dollarStrengths.length) return null;
    return (
      <div className="npp-section npp-strengths">
        {dollarStrengths.map((ds, i) => {
          const isComplete = !!(ds.amount && ds.text);
          const isPartial = !!(ds.amount || ds.text);
          if (!editMode && !isComplete) return null;
          const showFake = editMode && !isPartial;
          const fakeClass = showFake ? 'fake' : '';
          return (
            <React.Fragment key={i}>
              {(i > 0) &&
                <div className="npp-strengths-sep" />
              }
              <div className={`npp-strengths-strength ${fakeClass}`}>
                <div className="npp-strengths-strength-number">${showFake ? <FakeLines count={1} lineHeight={32} style="full" className="fake-amount" /> : ds.amount}</div>
                <div className="npp-strengths-strength-label">{showFake ? <FakeLines count={2} lineHeight={9} /> : ds.text}</div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  renderClaimCta() {
    const {showClaimCta, nonprofit} = this.props;
    if (!showClaimCta || !nonprofit) return null;

    return (
      <div className="npp-section">
        <div className="npp-claim">
          <div className="npp-claim-text">
            <h3 className="npp-claim-title">Is this your nonprofit?</h3>
            <p className="npp-claim-desc">Fill out your profile to be more discoverable and compelling to Millie donors.</p>
          </div>
          <Link className="btn secondary blue" href={paths.claim(nonprofit.id)}>Claim Profile</Link>
        </div>
      </div>
    );
  }

  renderFinancials() {
    const { nonprofit } = this.props;
    if (!nonprofit) return null;
    const {
      budgetTotal: total, budgetYear: year,
      budgetPercentProgram: percentProgram,
      budgetPercentManagement: percentManagement,
      budgetPercentFundraising: percentFundraising,
    } = nonprofit;
    if (!total) return null;
    const percentsTotal = (percentProgram || 0) + (percentManagement || 0) + (percentFundraising || 0);
    const percentsReasonable = (percentsTotal > 0.5) && (percentsTotal < 1.03);
    const formattedTotal = ((total || 0) >= 1000000)
      ? numeral(total).format('$0.0a').toUpperCase()
      : numeral(total).format('$0a');

    return (
      <div className="npp-section npp-finance">
        <div className="npp-section-header">
          <h3>Financials</h3>
        </div>
        <div className="npp-finance-cols">
          <div className="npp-finance-cols-budget">
            {!!total &&
              <div className="npp-finance-cols-budget-val">{formattedTotal}</div>
            }
            {!!year &&
              <div className="npp-finance-cols-budget-label">{year} Budget</div>
            }
          </div>
          {percentsReasonable && <>
            <div className="npp-finance-cols-sep" />
            <div className="npp-finance-cols-percents">
              {(percentProgram != null) &&
                <div className="npp-finance-percent program">
                  <span className="npp-finance-percent-val">{Math.round(percentProgram * 100)}%</span>
                  <span className="npp-finance-percent-label">Program Spend</span>
                </div>
              }
              {(percentManagement != null) &&
                <div className="npp-finance-percent management">
                  <span className="npp-finance-percent-val">{Math.round(percentManagement * 100)}%</span>
                  <span className="npp-finance-percent-label">Management Spend</span>
                </div>
              }
              {(percentFundraising != null) &&
                <div className="npp-finance-percent fundraising">
                  <span className="npp-finance-percent-val">{Math.round(percentFundraising * 100)}%</span>
                  <span className="npp-finance-percent-label">Fundraising Spend</span>
                </div>
              }
            </div>
          </>}
        </div>
        {percentsReasonable &&
          <div className="npp-finance-chart">
            {(percentProgram != null) &&
              <div className="npp-finance-chart-part program" style={{width: `${percentProgram * 100}%`}}>{Math.round(percentProgram * 100)}%</div>
            }
            {(percentManagement != null) &&
              <div className="npp-finance-chart-part management" style={{width: `${percentManagement * 100}%`}}>{Math.round(percentManagement * 100)}%</div>
            }
            {(percentFundraising != null) &&
              <div className="npp-finance-chart-part fundraising" style={{width: `${percentFundraising * 100}%`}}>{Math.round(percentFundraising * 100)}%</div>
            }
          </div>
        }
      </div>
    );
  }

  renderPrograms() {
    const { profile, editMode } = this.props;
    if (!profile) return null;
    const { programs } = profile;
    if (!programs) return null;
    const completePrograms = programs.filter((p) => (p.imgPath && p.title && p.text));
    if (!completePrograms.length && !editMode) return null;
    return (
      <div className="npp-section npp-programs">
        <div className="npp-section-header">
          <h3>Programs</h3>
        </div>
        <div className="npp-programs-items">
          {programs.map((program, i) => {
            const isComplete = !!(program.imgPath && program.title && program.text);
            const isPartial = !!(program.imgPath || program.title || program.text);
            if (!editMode && !isComplete) return null;
            const showFake = editMode && !isPartial;
            const fakeClass = showFake ? 'fake' : '';
            const imgUrl = showFake
              ? '/images/npp/placeholder-program.png'
              : program.imgPath
                ? `${config.cdnBaseUrl}/${program.imgPath}`
                : null;
            return (
              <div className={`npp-programs-item ${fakeClass}`} key={i}>
                {imgUrl &&
                  <div className="npp-programs-item-image" style={{backgroundImage: `url("${imgUrl}")`}} />
                }
                {showFake ? (
                  <FakeLines count={1} lineHeight={22} />
                ) : (
                  <h4 className="npp-programs-item-title">{program.title}</h4>
                )}
                {showFake ? (
                  <FakeLines count={5} lineHeight={9} />
                ) : (
                  <div className="npp-programs-item-description">{renderParagraph(program.text)}</div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderEvents() {
    const { profile, editMode } = this.props;
    if (!profile) return null;
    const { events } = profile;
    if (!events) return null;
    const completeEvents = events.filter((e) => (e.imgPath && e.title && e.text));
    if (!completeEvents.length && !editMode) return null;
    return (
      <div className="npp-section npp-events">
        <div className="npp-section-header">
          <h3>Volunteering + Events</h3>
        </div>
        <div className="npp-events-items">
          {events.map((event, i) => {
            const isComplete = !!(event.imgPath && event.title && event.text);
            const isPartial = !!(event.imgPath || event.title || event.text || event.url);
            if (!editMode && !isComplete) return null;
            const showFake = editMode && !isPartial;
            const fakeClass = showFake ? 'fake' : '';
            const imgUrl = showFake
              ? '/images/npp/placeholder-program.png'
              : event.imgPath
                ? `${config.cdnBaseUrl}/${event.imgPath}`
                : null;
            return (
              <div className="npp-events-item" key={i}>
                {imgUrl &&
                  <div className="npp-events-item-image" style={{backgroundImage: `url("${imgUrl}")`}} />
                }
                {showFake ? (
                  <FakeLines count={1} lineHeight={22} />
                ) : (
                  <h4 className="npp-events-item-title">{event.title}</h4>
                )}
                {showFake ? (
                  <FakeLines count={5} lineHeight={9} />
                ) : (
                  <div className="npp-events-item-description">{renderParagraph(event.text)}</div>
                )}
                {utils.isUrl(event.url) &&
                  <a href={event.url} target="_blank" className="npp-events-item-link">Learn More &gt;</a>
                }
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderWebsite() {
    const { nonprofit, profile, editMode } = this.props;
    const websiteUrl = editMode ? profile?.websiteUrl : nonprofit?.website;
    const isUrl = utils.isUrl(websiteUrl);
    if (!isUrl && !editMode) return null;
    const showFake = editMode && !profile?.websiteUrl;
    const prettyPart = (websiteUrl || '').replace('http://', '').replace('https://', '');
    return (
      <div className="npp-section npp-website">
        <span className="npp-website-title">Learn more at</span>
        <br />
        {showFake ? (
          <FakeLines count={1} lineHeight={9} className="npp-website-fake-link" style="full" />
        ) : (
          isUrl && (
            <a className="npp-website-link" href={websiteUrl} target="_blank">{prettyPart}</a>
          )
        )}
      </div>
    );
  }

  render() {
    const { nonprofit, editMode, bracketView } = this.props;
    if (!nonprofit) return null;
    const showBigDonate = (!editMode && !bracketView);
    const smallClass = bracketView ? 'small' : '';

    return (
      <div className={`nonprofit-profile ${smallClass}`} ref={this.refRoot}>
        {this.renderHero()}
        {this.renderBasic()}
        {this.renderClaimCta()}
        {this.renderPersonal()}
        {this.renderStats()}
        {this.renderVideo()}
        {this.renderDollarStrength()}
        {this.renderFinancials()}
        {this.renderPrograms()}
        {this.renderEvents()}
        {this.renderRecentDonors()}
        {showBigDonate && <Donate nonprofitId={nonprofit.id} />}
        {this.renderWebsite()}
      </div>
    );
  }

}

NonprofitProfile.propTypes = {
  nonprofit: PropTypes.object.isRequired,
  profile: PropTypes.object,
  editMode: PropTypes.bool,
  preferProfile: PropTypes.bool,
  bracketView: PropTypes.bool,
  showClaimCta: PropTypes.bool,
};

NonprofitProfile.defaultProps = {
  editMode: false,
  bracketView: false,
  preferProfile: false,
  showClaimCta: false,
};

export default NonprofitProfile;
