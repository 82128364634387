import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import MillieApi          from 'app/apis/millie';
import DatePicker         from 'app/components/common/date-picker';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import NonprofitInput     from 'app/components/common/nonprofit-input';
import StandardInput      from 'app/components/common/standard-input';
import StandardSelect     from 'app/components/common/standard-select';
import ImageInput         from 'app/components/common/uploaded-image-input';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import DtdBox             from 'app/components/wallet/dtd-box';
import config             from 'app/config';
import Duck               from 'app/ducks/offline-donations';
import history            from 'app/history';
import paths              from 'app/paths';
import AuthSlx            from 'app/selectors/auth';
import RoutingSlx         from 'app/selectors/routing';



const initialState = {
  receiptImgPath: null,
  nonprofit: null,
  amountInCents: null,
  donatedAtStr: null,
  note: null,
  campaigns: null,
  campaignsSearching: false,
  selectedCampaignId: null,
  dtdId: null,
};

class PageNewOfflineDonation extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = initialState;

    this.onChangeReceiptImg = this.onChangeReceiptImg.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onSelectCampaign = this.onSelectCampaign.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickSubmitAnother = this.onClickSubmitAnother.bind(this);
  }

  get hasIntl() {
    return !!_.get(this.props.currentUser, 'employment.company.features.international');
  }

  componentDidMount() {
    this.setDefaultsFromQuery();
  }

  onChangeReceiptImg(receiptImgPath) {
    this.setState({receiptImgPath});
  }

  onChangeNonprofit(nonprofit) {
    this.setState({nonprofit}, () => {
      this.checkCampaigns();
    });
  }

  onChangeAmount(event) {
    const amountInDollarsStr = _.get(event, 'target.value', '');
    const amountInDollars = parseFloat(amountInDollarsStr);
    const amountInCents = Number.isFinite(amountInDollars) ? Math.round(amountInDollars * 100) : null;
    this.setState({amountInCents});
  }

  onChangeDate(donatedAtStr) {
    this.setState({donatedAtStr}, () => {
      this.checkCampaigns();
    });
  }

  onSelectCampaign(selectedCampaignId) {
    this.setState({selectedCampaignId});
  }

  onChangeNote(event) {
    const note = event.target.value;
    this.setState({note});
  }

  onClickSubmit() {
    const { receiptImgPath, nonprofit: {id: nonprofitId}, amountInCents, donatedAtStr, note, dtdId, selectedCampaignId, campaigns } = this.state;
    const campaignId = (campaigns || []).find(c => c.id === selectedCampaignId)
      ? selectedCampaignId
      : null;
    this.props.create({receiptImgPath, nonprofitId, amountInCents, donatedAtStr, note, campaignId, dtdId});
  }

  onClickSubmitAnother() {
    this.setState(initialState);
    this.props.clearSuccess();
  }

  get isDtd() {
    return !!this.state.dtdId;
  }

  get canSubmit() {
    const { receiptImgPath, nonprofit, amountInCents, donatedAtStr, campaigns, selectedCampaignId } = this.state;
    const hasMatch = _.get(this.props.currentUser, 'employment.company.features.match');
    if (!hasMatch) return false;
    if (!nonprofit) return false;
    if (!receiptImgPath) return false;
    if (!Number.isFinite(amountInCents)) return false;
    if (amountInCents < 0 || amountInCents > 10000000) return false;
    if (!donatedAtStr) return false;
    if ((campaigns?.length > 0) && !campaigns.find(c => c.id === selectedCampaignId)) return false;
    return true;
  }

  checkCampaigns() {
    const {donatedAtStr, nonprofit} = this.state;
    const isReady = !!(nonprofit && donatedAtStr);
    this.setState({campaigns: null, campaignsSearching: isReady});
    if (!isReady) return;
    MillieApi.campaignsFetch({nonprofitId: nonprofit.id, includesDateStr: donatedAtStr}).then(({campaigns}) => {
      const newState = {campaigns, campaignsSearching: false};
      if (campaigns.length === 1) newState.selectedCampaignId = campaigns[0].id;
      this.setState(newState);
    });
  }

  setDefaultsFromQuery() {
    const {nonprofitId, dateStr, amount, receiptImgPath, dtdId} = this.props.query;
    if (nonprofitId) {
      MillieApi.nonprofitsGet(nonprofitId).then(({nonprofit}) => {
        if (nonprofit) this.onChangeNonprofit(nonprofit);
      });
    }
    if (dateStr && moment(dateStr).isValid()) {
      this.onChangeDate(dateStr)
    };

    const newState = {};
    if (amount) {
      const amountInCents = parseInt(amount);
      if (_.isFinite(amountInCents)) newState.amountInCents = amountInCents;
    };
    if (receiptImgPath) newState.receiptImgPath = receiptImgPath;
    if (dtdId) newState.dtdId = dtdId;
    if (Object.keys(newState).length) this.setState(newState);
  }

  renderCampaignInput() {
    const { campaigns, campaignsSearching, selectedCampaignId } =  this.state;
    if (campaignsSearching) return <p className="faint">Searching...</p>;
    if (campaigns == null) return <p className="faint">Select nonprofit and date first.</p>;
    if (campaigns.length === 0) return <p className="faint">No campaigns available for the given nonprofit and date.</p>;
    const options = campaigns.map((c) => ({label: c.name, value: c.id}));
    return <StandardSelect options={options} onSelect={this.onSelectCampaign} value={selectedCampaignId} />;
  }

  render() {
    const { currentUser, success, pending, validations } = this.props;
    const { receiptImgPath, nonprofit, amountInCents, donatedAtStr, note, campaigns, dtdId } = this.state;
    const amountInputVal = Number.isFinite(amountInCents) ? (amountInCents / 100) : '';
    const submitDisabled = !this.canSubmit || pending;
    const submitText = pending ? 'Submitting...' : 'Submit Offline Donation';

    return (
      <MainLayout className="page-offdon" bgColor="purple">
        <Meta title="New Offline Donation | Millie" />
        <div className="page-offdon-main-con">
          <div className="bform page-offdon-box">
            <h1 className="bform-h1">Submit Offline Donation for Match</h1>
            {success ? (<>
              <p>Thanks for submitting an offline match. 🎉<br />It has been sent to your employer for review.</p>
              <br />
              <button className="btn secondary small" onClick={this.onClickSubmitAnother}>Submit Another</button>
            </>) : (<>
              <div className="page-offdon-fields">
                <label className="bform-h3">Nonprofit I donated to:</label>
                <NonprofitInput onChange={this.onChangeNonprofit} selectedNonprofit={nonprofit} validations={validations} intl={this.hasIntl} />

                <label className="bform-h3">Donation date:</label>
                <DatePicker onChange={this.onChangeDate} placeholder="Donation Date" name="donatedAtStr" dateStr={donatedAtStr} validations={validations} />

                <label className="bform-h3">{campaigns?.length ? 'Millie Campaign to contribute to:' : 'Millie Campaign to contribute to (optional):'}</label>
                {this.renderCampaignInput()}

                <label className="bform-h3">Donation amount:</label>
                <StandardInput type="number" name="amountInCents" icon={<Icon.CurrencyDollar />} label="0.00" onChange={this.onChangeAmount} value={amountInputVal} validations={validations} />

                <div className="bform-label-row">
                  <label className="bform-h3">Donation receipt:</label>
                  {receiptImgPath && (
                    <a href={`${config.cdnBaseUrl}/${receiptImgPath}`} target="blank">View Receipt</a>
                  )}
                </div>
                <ImageInput allowPdf maxImageWidth={2048} name="receiptImgPath" label="Select Image or PDF File" onChange={this.onChangeReceiptImg} s3PathParts={['offline-donations', currentUser.id]} pathValue={receiptImgPath} validations={validations} />

                <label className="bform-h3">Note to nonprofit:</label>
                <StandardInput type="textarea" name="note" label="Note" onChange={this.onChangeNote} value={note || ''} validations={validations} />
              </div>
              <div className="bform-actions">
                <button className="btn midnight" disabled={submitDisabled} onClick={this.onClickSubmit}>{submitText}</button>
              </div>
              <p className="page-offdon-fp">Your company admin will be notified and this donation match will be pending approval.</p>
            </>)}
          </div>
          {this.isDtd && <DtdBox />}
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  pending: Duck.Slx.createPending(state),
  success: Duck.Slx.createSuccess(state),
  validations: Duck.Slx.createValidations(state),

  currentUser: AuthSlx.currentUser(state),
  query: RoutingSlx.query(state),
});

const dispatchToProps = (dispatch) => ({
  create: (attrs) => dispatch(Duck.Ax.create(attrs)),
  clearSuccess: () => dispatch(Duck.Ax.clearSuccess()),
});

export default connect(stateToProps, dispatchToProps)(PageNewOfflineDonation);
