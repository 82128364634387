import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-brackets-dashboard';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import ProgressBar        from 'app/components/common/progress-bar';
import Tip                from 'app/components/common/tip';
import CadminLayout       from 'app/components/company-admin/layout/';
import PageLoading        from 'app/components/layout/page-loading';
import config             from 'app/config';
import {
  Categories,
  CategoryNames,
  BracketRoundStatuses as RoundStatuses,
}                         from 'app/constants';
import helpers            from 'app/helpers/brackets';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-brackets-dashboard';

class PageCadminBracketsDashboard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelectVotersPage = this.onSelectVotersPage.bind(this);
  }

  onSelectVotersPage(page) {
    const { company, bracket, fetchVoters } = this.props;
    fetchVoters(company.slug, bracket.id, page);
  }

  renderParticipation() {
    const { dashboard } = this.props;
    const fmtPercent = dashboard ? numeral(dashboard.stats.participationPercent).format('0%') : '-';
    const fmtCount = dashboard ? numeral(dashboard.stats.participantCount).format('0,0') : '-';
    const fmtActivationCount = dashboard ? numeral(dashboard.stats.activationCount).format('0,0') : '-';
    const stats = [
      {label: 'Participation Rate', value: fmtPercent, message: 'Percentage of total employees who participated.'},
      {label: 'Participants', value: fmtCount},
      {label: 'New Sign Ups', value: fmtActivationCount, message: 'Employees who first signed in to participate in this bracket.'},
    ];
    return (
      <div className="ca-brkt-db-stats1">
        {stats.map((stat) => {
          return (
            <div className="ca-brkt-db-stats1-stat" key={stat.label}>
              <div className="ca-brkt-db-stats1-stat-val">{stat.value}</div>
              <div className="ca-brkt-db-stats1-stat-key tip-con">
                {stat.label}
                {stat.message && <Tip message={stat.message} />}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderEngagement() {
    const { dashboard } = this.props;
    if (!dashboard) return null;
    const maxPercent = Math.max(...dashboard.rounds.map(r => r.engagementPercent));
    const fmtPercent = numeral(dashboard.stats.engagementPercent).format('0%');
    return (
      <div className="ca-brkt-db-engage">
        <div className="ca-brkt-db-rchart">
          {dashboard.rounds.map((round) => {
            const height = ((round.engagementPercent / maxPercent) * 100) + '%';
            const fmtPercent = numeral(round.engagementPercent).format('0%');
            return (
              <div className="ca-brkt-db-rchart-rnd" key={round.number}>
                <div className="ca-brkt-db-rchart-rnd-barcon">
                  <div className="ca-brkt-db-rchart-rnd-bar" style={{height}}>
                    <div className="ca-brkt-db-rchart-rnd-bar-val">{fmtPercent}</div>
                  </div>
                </div>
                <div className="ca-brkt-db-rchart-rnd-label">{(round?.name || '').replace('Championship', 'Finals')}</div>
              </div>
            );
          })}
        </div>
        <div className="ca-brkt-db-engage-stat">
          <div className="ca-brkt-db-engage-stat-key tip-con">Avg Engagement <Tip message="Engagement is the average percentage of games played by those that have participated." /></div>
          <div className="ca-brkt-db-engage-stat-val">{fmtPercent}</div>
        </div>
      </div>
    );
  }

  renderRand() {
    const { dashboard } = this.props;
    // stats
    const fmtTotal = dashboard ? numeral(dashboard.stats.poolInCents / 100).format('$0,0') : '-';
    const fmtEmpTotal = dashboard ? numeral(dashboard.stats.empTotalInCents / 100).format('$0,0') : '-';
    const fmtDonorCount = dashboard ? numeral(dashboard.stats.donorCount).format('0,0') : '-';
    const fmtVoteCount = dashboard ? numeral(dashboard.stats.voteCount).format('0,0') : '-';
    const stats = [
      {label: 'Bracket Total', value: fmtTotal},
      {label: 'Added By Employees', value: fmtEmpTotal},
      {label: 'Donors', value: fmtDonorCount},
      {label: 'Votes', value: fmtVoteCount},
    ];
    // progress bars
    const roundCount = _.get(dashboard, 'rounds.length', 4);
    const completeRoundCount = (dashboard?.rounds || []).filter(r => r.status === RoundStatuses.COMPLETE).length;
    const empCount = dashboard ? dashboard.stats.employeeCount : 0;
    const participantCount = dashboard ? dashboard.stats.participantCount : 0;
    return (
      <div className="ca-brkt-db-rand">
        <div className="ca-brkt-db-stats2">
          {stats.map((stat) => {
            return (
              <div className="ca-brkt-db-stats2-stat" key={stat.label}>
                <div className="ca-brkt-db-stats2-stat-val">{stat.value}</div>
                <div className="ca-brkt-db-stats2-stat-key">{stat.label}</div>
              </div>
            );
          })}
        </div>
        <hr />
        <div>
          <ProgressBar
            title="Bracket Completed"
            targetLabel={`${roundCount} Rounds`}
            targetNumber={roundCount}
            actualNumber={completeRoundCount}
            emoji="💃"
            color="purple"
          />
          <ProgressBar
            title="Employee Participation"
            targetLabel={`${numeral(empCount).format('0,0')} Employees`}
            targetNumber={empCount}
            actualNumber={participantCount}
            emoji="🙌"
            color="purple"
          />
        </div>
      </div>
    );
  }

  renderNonprofits() {
    const { dashboard } = this.props;
    const top10 = _.sortBy((dashboard?.nonprofits || []), 'voteCount').reverse().slice(0, 10);
    return (
      <table className="ca-brkt-db-table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th className="right">Votes</th>
          </tr>
        </thead>
        <tbody>
          {top10.map((nonprofit, i) => {
            return (
              <tr key={nonprofit.id}>
                <td className="right faint">{i+1}</td>
                <td><Link href={paths.nonprofit(nonprofit)} className="pink-hover">{nonprofit.name}</Link></td>
                <td className="right">{nonprofit.voteCount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderPeople() {
    const { dashboard } = this.props;
    let urls = (dashboard?.nonprofits || [])
      .map(n => n.personalImgPath)
      .filter(p => p)
      .map(p => `${config.cdnBaseUrl}/${p}`);
    urls = [...new Set(urls)]; // dedupe
    return (
      <div className="ca-brkt-db-people">
        {urls.map(url => <img key={url} src={url} />)}
      </div>
    );
  }

  renderCategories() {
    const { dashboard } = this.props;
    const catPercentMap = {};
    const nonprofits = dashboard?.nonprofits || [];
    const eachPercentage = nonprofits.length ? (1 / nonprofits.length) : 0;
    nonprofits.forEach((nonprofit) => {
      const cat = nonprofit.category || Categories.OTHER;
      catPercentMap[cat] = (catPercentMap[cat] || 0) + eachPercentage;
    });
    const sortedCats = _.sortBy(Object.entries(catPercentMap).map(([cat, percent]) => ({cat, percent})), 'percent').reverse();
    return (
      <div className="ca-brkt-db-cats">
        {sortedCats.map(({cat, percent}) => (
          <div key={cat} className={`ca-brkt-db-cats-cat cat-bg-color-${cat}`} style={{width: `${percent * 100}%`}}><Icon.Category category={cat} /> {CategoryNames[cat]}</div>
        ))}
      </div>
    );
  }

  renderDirectStats() {
    const { dashboard } = this.props;
    const centsDirect = dashboard ? dashboard.stats.directDonationsTotalInCents : 0;
    const centsCombined = centsDirect + (dashboard ? dashboard.stats.poolInCents : 0);
    const fmtDirect = numeral(centsDirect / 100).format('$0,0');
    const fmtCombined = numeral(centsCombined / 100).format('$0,0');
    const stats = [
      {label: 'Donations within 60 days', value: fmtDirect},
      {label: 'Bracket with direct donations', value: fmtCombined},
    ];
    return (
      <div className="ca-brkt-db-stats3">
        {stats.map((stat) => {
          return (
            <div className="ca-brkt-db-stats3-stat" key={stat.label}>
              <div className="ca-brkt-db-stats3-stat-key">{stat.label}</div>
              <div className="ca-brkt-db-stats3-stat-val">{stat.value}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderDirectNonprofits() {
    const { dashboard } = this.props;
    const top5 = _.sortBy((dashboard?.nonprofits || []), 'directDonationAmountInCents').reverse().slice(0, 5);
    return (
      <table className="ca-brkt-db-table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th className="right">Donations</th>
            <th className="right">Amount</th>
          </tr>
        </thead>
        <tbody>
          {top5.map((nonprofit, i) => {
            return (
              <tr key={nonprofit.id}>
                <td className="right faint">{i+1}</td>
                <td><Link href={paths.nonprofit(nonprofit)} className="pink-hover">{nonprofit.name}</Link></td>
                <td className="right">{numeral(nonprofit.directDonationCount).format('0,0')}</td>
                <td className="right">{numeral(nonprofit.directDonationAmountInCents / 100).format('$0,0')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderGames() {
    const { bracket, games } = this.props;
    return (
      <table className="default ca-brkt-db-games-table">
        <thead>
          <tr>
            <th>Round</th>
            <th className="right">Participants</th>
            <th className="sep" colSpan="2">Nonprofit A</th>
            <th className="right"></th>
            <th className="sep" colSpan="2">Nonprofit B</th>
            <th className="right"></th>
          </tr>
        </thead>
        <tbody>
          {games.map((game) => {
            const totalVoteCount = game.aVoteCount + game.bVoteCount;
            const aPercent = game.aVoteCount / totalVoteCount;
            const bPercent = game.bVoteCount / totalVoteCount;
            const isAWinner = !!(game.winnerNonprofitId && game.winnerNonprofitId === game.aNonprofitId);
            const isBWinner = !!(game.winnerNonprofitId && game.winnerNonprofitId === game.bNonprofitId);
            const round = (bracket?.rounds || []).find(r => r.number === game.roundNumber);
            const roundName = round ? helpers.roundSizeNameMap[round.gameCount * 2] : '';
            return (
              <tr key={game.id}>
                <td>{roundName}</td>
                <td className="right">{numeral(totalVoteCount).format('0,0')}</td>
                <td className="right sep badge">{isAWinner && <Icon.LoveItRibbon1 />}</td>
                <td>
                  {game.aNonprofit && (
                    <Link className="pink-hover" href={paths.nonprofit(game.aNonprofit)}>{game.aNonprofit.name}</Link>
                  )}
                </td>
                <td className="right">{_.isFinite(game.aVoteCount) && numeral(aPercent).format('0%')}</td>
                <td className="right sep badge">{isBWinner && <Icon.LoveItRibbon1 />}</td>
                <td>
                  {game.bNonprofit && (
                    <Link className="pink-hover" href={paths.nonprofit(game.bNonprofit)}>{game.bNonprofit.name}</Link>
                  )}
                </td>
                <td className="right">{_.isFinite(game.bVoteCount) && numeral(bPercent).format('0%')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderVoters() {
    const { voters, votersPagination } = this.props;
    if (!voters) return <Icon.Loading className="ca-brkt-db-votes-loading" />;
    return (<>
      <table className="default ca-brkt-db-votes-table">
        <thead>
          <tr>
            <th>Employee</th>
            <th>Email</th>
            <th className="right">Votes</th>
            <th>Last Vote</th>
            <th className="right">Pool Donations</th>
          </tr>
        </thead>
        <tbody>
          {voters.map((voter) => {
            return (
              <tr key={voter.id}>
                <td>{`${voter.firstName} ${voter.lastName}`}</td>
                <td>{voter.email}</td>
                <td className="right">{voter.voteCount}</td>
                <td>{moment.unix(voter.lastVotedAtTs / 1000).format('ll')}</td>
                <td className="right">{numeral(voter.donationTotalInCents / 100).format('$0,0')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination pagination={votersPagination} onSelectPage={this.onSelectVotersPage} />
    </>);
  }

  render() {
    const { company, bracket, dashboard } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-brkt-db" company={company} activeItem="brackets" floatNav>
        <Meta title="Bracket Dashboard | Millie" />
        <div className="ca-brkt-db-head">
          <Link className="ca-back-crumb" href={paths.cadminBrackets(company.slug)}><Icon.Caret direction="left" /> Back to Brackets</Link>
          <h1 className="ca-brkt-db-head-h1"><Icon.Bracket /> {bracket.name}</h1>
          <div className={`ca-brkt-db-head-status status-${bracket.status}`}>{bracket.status}</div>
        </div>
        <div className="ca-brkt-db-main">
          <div className="ca-brkt-db-row1">
            <div className="ca-brkt-db-row1-left ca-brkt-db-box">
              <div className="ca-brkt-db-box-title">Engagement</div>
              {this.renderEngagement()}
            </div>
            <div className="ca-brkt-db-row1-right ca-brkt-db-box">
              <div className="ca-brkt-db-box-title">Participation</div>
              {this.renderParticipation()}
            </div>
          </div>
          <div className="ca-brkt-db-row2">
            <div className="ca-brkt-db-row2-left ca-brkt-db-box">
              {this.renderRand()}
            </div>
            <div className="ca-brkt-db-row2-right ca-brkt-db-box">
              <div className="ca-brkt-db-box-title">Top Nonprofits</div>
              {this.renderNonprofits()}
            </div>
          </div>
          <div className="ca-brkt-db-row3">
            <div className="ca-brkt-db-row3-left ca-brkt-db-box">
              <div className="ca-brkt-db-box-title">People We've Impacted</div>
              {this.renderPeople()}
            </div>
            <div className="ca-brkt-db-row3-right ca-brkt-db-box">
              <div className="ca-brkt-db-box-title">Cause Areas We Love</div>
              {this.renderCategories()}
            </div>
          </div>
          <div className="ca-brkt-db-row4 ca-brkt-db-box">
            <div className="ca-brkt-db-row4-left">
              <div className="ca-brkt-db-box-title tip-con">
                Donations to bracket nonprofits
                <Tip message="Donations (outside of the pool) directly to nonprofits that are in the bracket, from the time the bracket is published through 60 days after the bracket is completed." />
              </div>
              {this.renderDirectStats()}
            </div>
            <div className="ca-brkt-db-row4-right">
              <div className="ca-brkt-db-box-title">Nonprofits</div>
              {this.renderDirectNonprofits()}
            </div>
          </div>
          <div className="ca-brkt-db-row5">
            <div className="ca-brkt-db-box">
              <div className="ca-brkt-db-box-title">Games</div>
              <br />
              {this.renderGames()}
            </div>
          </div>
          <div className="ca-brkt-db-row6">
            <div className="ca-brkt-db-box">
              <div className="ca-brkt-db-box-title">Votes</div>
              <br />
              {this.renderVoters()}
            </div>
          </div>
        </div>  
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  bracket: PageSlx.bracket(state),
  dashboard: PageSlx.dashboard(state),
  games: PageSlx.games(state),
  voters: PageSlx.voters(state),
  votersPagination: PageSlx.votersPagination(state),
});

const dispatchToProps = (dispatch) => ({
  fetchVoters: (companySlug, bracketId, page) => dispatch(PageAx.fetchVoters(companySlug, bracketId, page)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminBracketsDashboard);
