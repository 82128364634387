import axios from 'app/axios';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

// import axios3 from 'app/axios';

import AccountAx                     from 'app/actions/account';
import ActionsAx                     from 'app/actions/actions';
import AuthAx                        from 'app/actions/auth';
import CampaignsAx                   from 'app/actions/campaigns';
import CollectionsAx                 from 'app/actions/collections';
import CadminTransactionsAx          from 'app/actions/company-admin/balance-transactions';
import CadminDashboardBuilderAx      from 'app/actions/company-admin/builder-dashboard';
import CadminBracketsAx              from 'app/actions/company-admin/brackets';
import CadminCampaignsAx             from 'app/actions/company-admin/campaigns';
import CadminCompaniesAx             from 'app/actions/company-admin/companies';
import CadminDashboardsAx            from 'app/actions/company-admin/company-dashboards';
import CadminCompanyPurchasesAx      from 'app/actions/company-admin/company-purchases';
import CadminGroupsAx                from 'app/actions/company-admin/groups';
import CadminSlackChannelsAx         from 'app/actions/company-admin/slack-channels';
import CadminWalletAx                from 'app/actions/company-admin/wallet';
import CompanyDashboardsAx           from 'app/actions/company-dashboards';
import CountriesAx                   from 'app/actions/countries';
import DriveDonationsAx              from 'app/actions/drive-donations';
import GroupsAx                      from 'app/actions/groups';
import PageEmployeeInviteAx          from 'app/actions/page-employee-invite';
import PageNonprofitProfileEditAx    from 'app/actions/page-nonprofit-profile-edit';
import PageNonprofitsAx              from 'app/actions/page-nonprofits';
import RoutingAx                     from 'app/actions/routing';
import UsersAx                       from 'app/actions/users';
import SessionStorageAx              from 'app/actions/session-storage';
import ToastAx                       from 'app/actions/toast';
import VolEventsAx                   from 'app/actions/vol-events';
import analytics                     from 'app/analytics';
import backstageApi, {
  client as backstageClient,
}                                    from 'app/apis/backstage';
import companyAdminApi, {
  client as companyAdminClient,
}                                    from 'app/apis/company-admin';
import googleMaps                    from 'app/apis/google-maps';
import millieApi, {
  client as millieClient,
}                                    from 'app/apis/millie';
import plaid                         from 'app/apis/plaid';
import confetti                      from 'app/confetti';
import config                        from 'app/config';
import CitiesDuck                    from 'app/ducks/cities';
import CreditCardsDuck               from 'app/ducks/credit-cards';
import CadminBudgetsDuck             from 'app/ducks/company-admin/budgets';
import CadminChargeErrorsDuck        from 'app/ducks/company-admin/charge-errors';
import CadminCompanyNteeRulesDuck    from 'app/ducks/company-admin/company-ntee-rules';
import CadminEmployeesDuck           from 'app/ducks/company-admin/employees';
import CadminPageGroupsSettingsDuck  from 'app/ducks/company-admin/page-groups-settings';
import EmojisDuck                    from 'app/ducks/emojis';
import FavoritesDuck                 from 'app/ducks/favorites';
import FxRatesDuck                   from 'app/ducks/fx-rates';
import HappeningsDuck                from 'app/ducks/happenings';
import ModalConfirmDonationDuck      from 'app/ducks/modal-confirm-donation';
import NteeCodesDuck                 from 'app/ducks/ntee-codes';
import PageIntlOrgsSearchDuck        from 'app/ducks/page-intl-orgs-search';
import PromptsDuck                   from 'app/ducks/prompts';
import * as ReviewPromptDuck         from 'app/ducks/review-prompt';
import SocialDuck                    from 'app/ducks/social';
import fonts                         from 'app/fonts';
import cdn                           from 'app/helpers/cdn';
import fees                          from 'app/helpers/fees';
import format                        from 'app/helpers/format';
import utils                         from 'app/helpers/utils';
import history                       from 'app/history';
import paths                         from 'app/paths';
import pricing                       from 'app/pricing';
import prompts                       from 'app/prompts';
import AuthSlx                       from 'app/selectors/auth';
import CadminSlx                     from 'app/selectors/company-admin/';
import CadminBracketBuilderSlx       from 'app/selectors/company-admin/builder-bracket';
import CadminCampaignBuilderSlx      from 'app/selectors/company-admin/builder-campaign';
import CadminDashboardBuilderSlx     from 'app/selectors/company-admin/builder-dashboard';
import CadminGroupBuilderSlx         from 'app/selectors/company-admin/builder-group';
import CadminVolEventBuilderSlx      from 'app/selectors/company-admin/builder-vol-event';
import CadminPageEmployeesSlx        from 'app/selectors/company-admin/page-employees';
import CadminPageGroupsSlx           from 'app/selectors/company-admin/page-groups';
import CadminPageGroupsEditSlx       from 'app/selectors/company-admin/page-groups-edit';
import CadminPageGroupsNewSlx        from 'app/selectors/company-admin/page-groups-new';
import CadminPageMatchesSlx          from 'app/selectors/company-admin/page-matches';
import CadminPageOffDonsSlx          from 'app/selectors/company-admin/page-offline-donations';
import CadminPageSettingsSlx         from 'app/selectors/company-admin/page-settings';
import CadminPageVolEventsSlx        from 'app/selectors/company-admin/page-vol-events';
import CadminPageVolEventsEditSlx    from 'app/selectors/company-admin/page-vol-events-edit';
import CountriesSlx                  from 'app/selectors/countries';
import EntitiesSlx                   from 'app/selectors/entities';
import FeatureFlagsSlx               from 'app/selectors/feature-flags';
import PageCampaignSlx               from 'app/selectors/page-campaign';
import PageCompanyDashboardSlx       from 'app/selectors/page-company-dashboard';
import PageEmployeeInviteSlx         from 'app/selectors/page-employee-invite';
import PageNonprofitDashboardSlx     from 'app/selectors/page-nonprofit-dashboard';
import PageNonprofitProfileSlx       from 'app/selectors/page-nonprofit-profile';
import PageNonprofitProfileEditSlx   from 'app/selectors/page-nonprofit-profile-edit';
import PageVolunteerSlx              from 'app/selectors/page-volunteer';
import PageWalletSlx                 from 'app/selectors/page-wallet';
import RoutingSlx                    from 'app/selectors/routing';
import SessionStorageSlx             from 'app/selectors/session-storage';
import VolEventsSlx                  from 'app/selectors/vol-events';
import WalletSlx                     from 'app/selectors/wallet';

const setGlobals = (items={}) => {
  const MILLIE = {
    ...items,
    axios,
    _,
    moment,
    numeral,
    config,
    googleMaps,
    history,
    paths,
    pricing,
    prompts,
    plaid,
    cdn,
    fees,
    format,
    utils,
    analytics,
    millieClient,
    backstageClient,
    companyAdminClient,
    confetti,
    fonts,
    Ax: {
      Account: AccountAx,
      Actions: ActionsAx,
      Auth: AuthAx,
      Campaigns: CampaignsAx,
      Collections: CollectionsAx,
      PageNonprofits: PageNonprofitsAx,
      PageEmployeeInvite: PageEmployeeInviteAx,
      PageNonprofitProfileEdit: PageNonprofitProfileEditAx,
      Routing: RoutingAx,
      Users: UsersAx,
      SessionStorage: SessionStorageAx,
      Toast: ToastAx,
      CadminBrackets: CadminBracketsAx,
      CadminCampaigns: CadminCampaignsAx,
      VolEvents: VolEventsAx,
      CadminCompanies: CadminCompaniesAx,
      CadminDashboards: CadminDashboardsAx,
      CadminCompanyPurchases: CadminCompanyPurchasesAx,
      CadminDashboardBuilder: CadminDashboardBuilderAx,
      CadminGroups: CadminGroupsAx,
      CadminSlackChannels: CadminSlackChannelsAx,
      CadminWallet: CadminWalletAx,
      CadminTransactions: CadminTransactionsAx,
      CompanyDashboards: CompanyDashboardsAx,
      DriveDonations: DriveDonationsAx,
      Groups: GroupsAx,
      Countries: CountriesAx,
    },
    Apis: {
      Millie: millieApi,
      Backstage: backstageApi,
      CompanyAdmin: companyAdminApi,
    },
    Slx: {
      Auth: AuthSlx,
      Cadmin: CadminSlx,
      CadminBracketBuilder: CadminBracketBuilderSlx,
      CadminCampaignBuilder: CadminCampaignBuilderSlx,
      CadminDashboardBuilder: CadminDashboardBuilderSlx,
      CadminGroupBuilder: CadminGroupBuilderSlx,
      CadminVolEventBuilder: CadminVolEventBuilderSlx,
      CadminPageEmployees: CadminPageEmployeesSlx,
      CadminPageGroups: CadminPageGroupsSlx,
      CadminPageGroupsEdit: CadminPageGroupsEditSlx,
      CadminPageGroupsNew: CadminPageGroupsNewSlx,
      CadminPageMatches: CadminPageMatchesSlx,
      CadminPageVolEvents: CadminPageVolEventsSlx,
      CadminPageVolEventsEdit: CadminPageVolEventsEditSlx,
      CadminPageOffDons: CadminPageOffDonsSlx,
      CadminPageSettings: CadminPageSettingsSlx,
      Countries: CountriesSlx,
      Entities: EntitiesSlx,
      FeatureFlags: FeatureFlagsSlx,
      PageCampaign: PageCampaignSlx,
      PageCompanyDashboard: PageCompanyDashboardSlx,
      PageEmployeeInvite: PageEmployeeInviteSlx,
      PageNonprofitDashboard: PageNonprofitDashboardSlx,
      PageNonprofitProfile: PageNonprofitProfileSlx,
      PageNonprofitProfileEdit: PageNonprofitProfileEditSlx,
      PageVolunteer: PageVolunteerSlx,
      PageWallet: PageWalletSlx,
      Routing: RoutingSlx,
      SessionStorage: SessionStorageSlx,
      VolEvents: VolEventsSlx,
      Wallet: WalletSlx,
    },
    Ducks: {
      ReviewPrompt: ReviewPromptDuck,
      Cities: CitiesDuck,
      CreditCards: CreditCardsDuck,
      CadminPageGroupsSettings: CadminPageGroupsSettingsDuck,
      CadminBudgets: CadminBudgetsDuck,
      CadminChargeErrors: CadminChargeErrorsDuck,
      CadminCompanyNteeRules: CadminCompanyNteeRulesDuck,
      CadminEmployees: CadminEmployeesDuck,
      Emojis: EmojisDuck,
      Favorites: FavoritesDuck,
      FxRates: FxRatesDuck,
      Happenings: HappeningsDuck,
      NteeCodes: NteeCodesDuck,
      PageIntlOrgsSearch: PageIntlOrgsSearchDuck,
      Prompts: PromptsDuck,
      ModalConfirmDonation: ModalConfirmDonationDuck,
      Social: SocialDuck,
    },
  };

  const hasBackstage = _.get(window, '__MILLIE.hasBackstage', false);
  if (process.env.NODE_ENV === 'development' || hasBackstage) {
    window.MILLIE = MILLIE;
  }
};

export default setGlobals;
