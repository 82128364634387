import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import BackstageApi    from 'app/apis/backstage';
import UserInput       from 'app/components/backstage/common/user-input';
import BackstageLayout from 'app/components/backstage/layout';
import CurrencyInput   from 'app/components/common/currency-input';
import Icon            from 'app/components/common/icon';
import IntegerInput    from 'app/components/common/integer-input';
import Link            from 'app/components/common/link';
import DateRangePicker from 'app/components/common/date-range-picker';
import StandardSelect  from 'app/components/common/standard-select';
import EntityInput     from 'app/components/company-admin/common/entity-input';
import {
  DisbursementCauses as Causes,
  PercentPaymentStatuses,
  PercentPaymentMethods,
}                      from 'app/constants';
import countries       from 'app/helpers/countries';
import format          from 'app/helpers/format';
import history         from 'app/history';
import paths           from 'app/paths';
import RoutingSlx      from 'app/selectors/routing';

const dafOpts = [
  {label: 'Percent', value: 'percent'},
  {label: 'Givinga', value: 'givinga'},
];
const regionOpts = [
  {label: 'US-only', value: 'US'},
  {label: 'International-only', value: '!US'},
];
const causeOpts = Object.values(Causes).map((cause) => {
  return {label: cause, value: cause};
});
const disbursedOpts = [
  {label: 'Sent', value: 'true'},
  {label: 'Failed', value: 'false'},
];
const ppsOpts = Object.values(PercentPaymentStatuses).map((pps) => {
  return {label: pps, value: pps};
});
ppsOpts.push({label: '-pending-', value: 'null'});
const ppmOpts = Object.values(PercentPaymentMethods).map((ppm) => {
  return {label: ppm, value: ppm};
});
ppmOpts.push({label: '-tbd-', value: 'null'});

class BackstagePageDisbursements extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      disbs: null,
      pagination: null,
      summary: null,
      user: null,
    };

    this.onSelectDaf = this.onSelectDaf.bind(this);
    this.onSelectCause = this.onSelectCause.bind(this);
    this.onSelectRegion = this.onSelectRegion.bind(this);
    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onSelectDisbursed = this.onSelectDisbursed.bind(this);
    this.onSelectPps = this.onSelectPps.bind(this);
    this.onSelectPpm = this.onSelectPpm.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeEmployee = this.onChangeEmployee.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);
    this.onChangeMinAmount = this.onChangeMinAmount.bind(this);
    this.onChangeMaxAmount = this.onChangeMaxAmount.bind(this);
    this.onChangeMinActualAmount = this.onChangeMinActualAmount.bind(this);
    this.onChangeMaxActualAmount = this.onChangeMaxActualAmount.bind(this);
    this.onChangeDaysSinceDisbursed = _.debounce(this.onChangeDaysSinceDisbursed.bind(this), 500);
  }

  /*
   *  Life Cycle
   */

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const isStillOnPage = this.props.path === '/backstage/disbursements';
    if ((prevProps.url !== this.props.url) && isStillOnPage) {
      this.fetch();
    }
  }

  /*
   *  Helpers
   */

  get daf() {
    return this.props.query.daf || null;
  }
  get cause() {
    return this.props.query.cause || null;
  }
  get disbursed() {
    return this.props.query.disbursed || null;
  }
  get pps() {
    return this.props.query.percentPaymentStatus || null;
  }
  get region() {
    return this.props.query.countryCode || null;
  }
  get startDateStr() {
    return this.props.query.startDateStr || null;
  }
  get endDateStr() {
    return this.props.query.endDateStr || null;
  }
  get ppm() {
    return this.props.query.percentPaymentMethod || null;
  }
  get minAmount() {
    return parseInt((this.props.query.minAmount          || '').replace(/[^0-9]/g, '') || '0') || null;
  }
  get maxAmount() {
    return parseInt((this.props.query.maxAmount          || '').replace(/[^0-9]/g, '') || '0') || null;
  }
  get minActualAmount() {
    return parseInt((this.props.query.minActualAmount    || '').replace(/[^0-9]/g, '') || '0') || null;
  }
  get maxActualAmount() {
    return parseInt((this.props.query.maxActualAmount    || '').replace(/[^0-9]/g, '') || '0') || null;
  }
  get daysSinceDisbursed() {
    return parseInt((this.props.query.daysSinceDisbursed || '').replace(/[^0-9]/g, '') || '0') || null;
  }

  pathWithNewParams(newParams={}) {
    return paths.bsDisbursements({...this.props.query, page: 1, ...newParams});
  }

  /*
   *  Tasks
   */

  fetch() {
    const {query} = this.props;
    this.setState({disbs: null, pagination: null});
    const fetchKey = `${Math.random()}`;
    this.fetchKey = fetchKey;
    BackstageApi.disbursementsSearch(query).then(({disbursements, pagination, summary, user}) => {
      if (this.fetchKey !== fetchKey) return;
      this.setState({disbs: disbursements, pagination, summary, user});
    });
  }

  updateParams(newParams) {
    const path = this.pathWithNewParams(newParams);
    history.push(path);    
  }

  /*
   *  Handlers
   */

  onSelectDaf(daf) {
    this.updateParams({daf});
  }

  onSelectCause(cause) {
    this.updateParams({cause});
  }

  onSelectRegion(countryCode) {
    this.updateParams({countryCode});
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.updateParams({startDateStr, endDateStr});
  }

  onSelectDisbursed(disbursed) {
    this.updateParams({disbursed});
  }

  onSelectPps(percentPaymentStatus) {
    this.updateParams({percentPaymentStatus});
  }

  onSelectPpm(percentPaymentMethod) {
    this.updateParams({percentPaymentMethod});
  }

  onChangeNonprofit(nonprofit) {
    this.updateParams({nonprofitId: nonprofit?.id || null});
  }
  onChangeCompany(company) {
    this.updateParams({companyId: company?.id || null, employeeId: null});
  }
  onChangeEmployee(employee) {
    this.updateParams({employeeId: employee?.id || null});
  }
  onChangeUser(user) {
    this.updateParams({userId: user?.id || null});
  }

  onChangeMinAmount({amount}) {
    this.updateParams({minAmount: amount || null});
  }
  onChangeMaxAmount({amount}) {
    this.updateParams({maxAmount: amount || null});
  }
  onChangeMinActualAmount({amount}) {
    this.updateParams({minActualAmount: amount || null});
  }
  onChangeMaxActualAmount({amount}) {
    this.updateParams({maxActualAmount: amount || null});
  }

  onChangeDaysSinceDisbursed(daysSinceDisbursed) {
    this.updateParams({daysSinceDisbursed});
  }

  /*
   *  Render
   */

  renderFilters() {
    const {nonprofitId, companyId, employeeId, userId} = this.props.query;

    return (<>
      <div className="bs-page-filters">
        <div className="bs-page-filters-filter">
          <label>Type</label>
          <StandardSelect options={causeOpts} onSelect={this.onSelectCause} label="Any" value={this.cause} allowClear  />
        </div>
        <div className="bs-page-filters-filter">
          <label>US/INTL</label>
          <StandardSelect options={regionOpts} onSelect={this.onSelectRegion} label="Any" value={this.region} allowClear  />
        </div>
        <div className="bs-page-filters-filter">
          <label>Min Amount</label>
          <CurrencyInput onChange={this.onChangeMinAmount} amount={this.minAmount} changeOnBlur />
        </div>
        <div className="bs-page-filters-filter">
          <label>Max Amount</label>
          <CurrencyInput onChange={this.onChangeMaxAmount} amount={this.maxAmount} changeOnBlur />
        </div>
        <div className="bs-page-filters-filter">
          <label>Min Actual Amount</label>
          <CurrencyInput onChange={this.onChangeMinActualAmount} amount={this.minActualAmount} changeOnBlur />
        </div>
        <div className="bs-page-filters-filter">
          <label>Max Actual Amount</label>
          <CurrencyInput onChange={this.onChangeMaxActualAmount} amount={this.maxActualAmount} changeOnBlur />
        </div>
      </div>
      <div className="bs-page-filters">
        <div className="bs-page-filters-filter">
          <label>(To) Nonprofit</label>
          <EntityInput.Nonprofit intl onChange={this.onChangeNonprofit} nonprofitId={nonprofitId} label="Any" />
        </div>
        <div className="bs-page-filters-filter">
          <label>(From) User</label>
          <UserInput onChange={this.onChangeUser} selectedUser={this.state.user} label="Any" />
        </div>
        <div className="bs-page-filters-filter">
          <label>(From) Company</label>
          <EntityInput.Company onChange={this.onChangeCompany} companyId={companyId} label="Any" />
        </div>
        <div className="bs-page-filters-filter">
          <label>(From) Employee</label>
          <EntityInput.Employee onChange={this.onChangeEmployee} companyId={companyId} employeeId={employeeId} label="Any" disabled={!companyId} />
        </div>
      </div>
      <div className="bs-page-filters">
        <div className="bs-page-filters-filter">
          <label>Sent to DAF Date Range</label>
          <DateRangePicker onSelect={this.onSelectDateRange} leftAlign allowClear label="All Dates" startDateStr={this.startDateStr} endDateStr={this.endDateStr} />
        </div>
        <div className="bs-page-filters-filter">
          <label>DAF</label>
          <StandardSelect options={dafOpts} onSelect={this.onSelectDaf} label="Any" value={this.daf} allowClear  />
        </div>
        <div className="bs-page-filters-filter">
          <label>Sent to DAF</label>
          <StandardSelect options={disbursedOpts} onSelect={this.onSelectDisbursed} label="Any" value={this.disbursed} allowClear  />
        </div>
        <div className="bs-page-filters-filter">
          <label>Days Since Sent to DAF</label>
          <IntegerInput value={this.daysSinceDisbursed} onChange={this.onChangeDaysSinceDisbursed} label="30" />
        </div>
        <div className="bs-page-filters-filter">
          <label>Percent Payment Status</label>
          <StandardSelect options={ppsOpts} onSelect={this.onSelectPps} label="Any" value={this.pps} allowClear  />
        </div>
        <div className="bs-page-filters-filter">
          <label>Percent Payment Method</label>
          <StandardSelect options={ppmOpts} onSelect={this.onSelectPpm} label="Any" value={this.ppm} allowClear  />
        </div>
      </div>
      <div className="bs-page-filters">
        <div className="bs-page-filters-filter">
          <label>Common Opts</label>
          <div className="btn-row">
            <Link href={paths.bsDisbursements({disbursed: false})} className="btn small secondary">Failed</Link>
            <Link href={paths.bsDisbursements({disbursed: true, daysSinceDisbursed: 45, minActualAmount: 4500, daf: 'percent', percentPaymentStatus: 'null'})} className="btn small secondary">Past Due</Link>
          </div>
        </div>
      </div>
    </>);
  }

  renderTable() {
    const {disbs, summary} = this.state;
    if (!disbs) return 'Loading...';
    if (!disbs.length) return 'No results. You might need to check the filters.';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <td></td>
            <td></td>
            <td>{summary?.nonprofitCount || 0} Distinct Nonprofits</td>
            <td></td>
            <td></td>
            <td className="right">{format.usd(summary?.amount || 0)}</td>
            <td className="right">{format.usd(summary?.actualAmount || 0)}</td>
            <td></td>
            <td><a href={paths.bsDisbursementsCsv(this.props.query)} className="btn xs">CSV</a></td>
          </tr>
          <tr>
            <th className="right">Created</th>
            <th className="right">Sent to DAF</th>
            <th>Nonprofit</th>
            <th>Type</th>
            <th>Donor</th>
            <th className="right">Amount</th>
            <th className="right">Actual Amount</th>
            <th>DAF</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {disbs.map((disb) => {
            const {nonprofit, company, employee, user} = disb;
            const donor = (() => {
              if ((disb.causeType === Causes.DONATION) && user) return `${user.firstName} ${user.lastName}`;
              if ((disb.causeType === Causes.FUND) && company) return `Pooled Fund (${company.name})`;
              if (company) return company.name;
              return '';
            })();
            const flag = countries.byCode[nonprofit?.countryCode]?.flag;
            return (<tr key={disb.id}>
              <td className="right">{moment.utc(disb.createdAt).format('MMM D, YYYY')}</td>
              <td className="right">{disb.disbursedAt ? moment.utc(disb.disbursedAt).format('MMM D, YYYY') : <Icon.GoodOrBad isGood={false} />}</td>
              <td>
                {`${flag} ${nonprofit?.name}`}<br />
                <Link href={paths.bsNonprofit(nonprofit?.id)}>View in Backstage</Link>
                &nbsp;&bull;&nbsp;
                <Link href={paths.nonprofit(nonprofit)}>Public Profile</Link>
              </td>
              <td>{disb.causeType}</td>
              <td>{donor}</td>
              <td className="right">{format.usd(disb.amountInCents)}</td>
              <td className="right">{format.usd(disb.actualAmountInCents)}</td>
              <td>{disb.daf}</td>
              <td>
                <Link href={paths.bsDisbursement(disb.id)} className="btn xs">Details</Link>
              </td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  renderPagination() {
    const {pagination} = this.state;
    if (!pagination) return null;
    const {resultCount, pageCount, page} = pagination;

    return (
      <h4>
        <Link href={this.pathWithNewParams({page: Math.max(page - 1, 1)})}>&lt; Prev</Link>
        &nbsp;&nbsp;&nbsp;
        <span>{numeral(resultCount).format('0,0')} Results.</span>
        &nbsp;
        <span>Page {page} of {numeral(pageCount).format('0,0')}.</span>
        &nbsp;&nbsp;&nbsp;
        <Link href={this.pathWithNewParams({page: Math.min(page + 1, pageCount)})}>Next &gt;</Link>
      </h4>
    );
  }

  render() {
    const notes = [];
    if (this.daysSinceDisbursed) notes.push(`FYI - Exact "Days Since Sent to DAF" may be off by a day cuz timezones.`);
    if (this.startDateStr) notes.push(`FYI - Date Range dates are both inclusive, and UTC-based.`);
    if (this.daysSinceDisbursed && this.startDateStr) notes.push(`FYI - Date Range and "Days Since Sent to DAF" may conflict with each other.`);
    return (
      <BackstageLayout>
        <div className="bspage-disbs bs-page">
          <div className="bspage-disbs-head">
            <h1 className="bs-page-h1">Disbursements</h1>
          </div>
          {this.renderFilters()}
          <br />
          {notes.map((note, i) => {
            return <p key={i}><span style={{display: 'inline-block', width: 24, color: '#FE8232'}}><Icon.InfoCircle /></span> {note}</p>;
          })}
          {this.renderPagination()}
          {this.renderTable()}
          {this.renderPagination()}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  url: RoutingSlx.url(state),
  path: RoutingSlx.path(state),
  query: RoutingSlx.query(state),
});

export default connect(stateToProps)(BackstagePageDisbursements);
