import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import numeral from 'numeral';

import BackstageApi    from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Checkbox        from 'app/components/common/checkbox';
import IntegerInput    from 'app/components/common/integer-input';
import paths           from 'app/paths';
import history         from 'app/history';
import pricing         from 'app/pricing';
import RoutingSlx      from 'app/selectors/routing';

class BackstagePagesPricing extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      sliderEmpCount: null,
      scriptStr: null,
    };

    this.hasFetchedScript = false;

    this.onChangeEmpCount = this.onChangeEmpCount.bind(this);
    this.onChangeEmpCountSlider = this.onChangeEmpCountSlider.bind(this);
    this.updateFromSlider = _.debounce(this.updateFromSlider.bind(this), 300);
    this.onChangeMatch = this.onChangeMatch.bind(this);
    this.onChangeIntl = this.onChangeIntl.bind(this);
    this.onChangeVol = this.onChangeVol.bind(this);
    this.onChangeGroups = this.onChangeGroups.bind(this);
    this.onChangeAnalytics = this.onChangeAnalytics.bind(this);
    this.onChangeSso = this.onChangeSso.bind(this);
    this.onChangePayroll = this.onChangePayroll.bind(this);
    this.onChangeMadness = this.onChangeMadness.bind(this);
  }

  componentDidMount() {
    BackstageApi.pricingGetScript().then((scriptStr) => {
      this.setState({scriptStr});
    });
  }

  get empCount() {
    if (_.isFinite(this.state.sliderEmpCount)) return this.state.sliderEmpCount;
    if (!this.props.query.empCount) return 100;
    const intVal = parseInt(this.props.query.empCount);
    if (!_.isFinite(intVal)) return 100;
    return Math.max(intVal, 0);
  }
  get match() {
    if (this.props.query.match === 'true') return true;
    if (!this.props.query.match) return true;
    return false;
  }
  get intl() {
    return this.props.query.intl === 'true';
  }
  get vol() {
    return this.props.query.vol === 'true';
  }
  get groups() {
    return this.props.query.groups === 'true';
  }
  get analytics() {
    return this.props.query.analytics === 'true';
  }
  get sso() {
    return this.props.query.sso === 'true';
  }
  get payroll() {
    return this.props.query.payroll === 'true';
  }
  get madness() {
    return this.props.query.madness === 'true';
  }
  get price() {
    const {empCount, match, intl, vol, groups, sso, analytics, payroll, madness} = this;
    return pricing.getPrice(empCount, {match, intl, vol, groups, sso, payroll, analytics, madness});
  }

  updateParams(newParams) {
    const path = this.pathWithNewParams(newParams);
    history.push(path);
    this.setState({sliderEmpCount: null});
  }
  pathWithNewParams(newParams={}) {
    return paths.bsPricing({...this.props.query, ...newParams});
  }

  onChangeEmpCount(empCount) {
    this.updateParams({empCount: (empCount || 0)});
  }
  onChangeEmpCountSlider(event) {
    let sliderEmpCount = parseInt(event.target.value || '0');
    if (!_.isFinite(sliderEmpCount)) sliderEmpCount = null;
    this.setState({sliderEmpCount});
    this.updateFromSlider();
  }
  updateFromSlider() {
    if (_.isFinite(this.state.sliderEmpCount)) {
      this.updateParams({empCount: this.state.sliderEmpCount});
    }
  }
  onChangeMatch(event) {
    const match = event.target.checked;
    this.updateParams({match});
  }
  onChangeIntl(event) {
    const intl = event.target.checked;
    this.updateParams({intl});
  }
  onChangeVol(event) {
    const vol = event.target.checked;
    this.updateParams({vol});
  }
  onChangeGroups(event) {
    const groups = event.target.checked;
    this.updateParams({groups});
  }
  onChangeAnalytics(event) {
    const analytics = event.target.checked;
    this.updateParams({analytics});
  }
  onChangeSso(event) {
    const sso = event.target.checked;
    this.updateParams({sso});
  }
  onChangePayroll(event) {
    const payroll = event.target.checked;
    this.updateParams({payroll});
  }
  onChangeMadness(event) {
    const madness = event.target.checked;
    this.updateParams({madness});
  }

  render() {
    const {scriptStr} = this.state;

    return (
      <BackstageLayout>
        <div className="bs-page page-bs-pricing">
          <div className="page-bs-pricing-head">
            <h1 className="bs-page-h1">Pricing</h1>
            <p>Version: Feb 2025</p>
          </div>

          <div className="bform">
            <h2 className="bform-h2">1. Enter Employee Count</h2>
            <IntegerInput label="Employee Count" value={this.empCount} onChange={this.onChangeEmpCount} />
            <input style={{width: '100%'}} type="range" min="1" max="5000" step="1" onChange={this.onChangeEmpCountSlider} value={this.empCount} />

            <h2 className="bform-h2">2. Select Modules</h2>
            <div className="style-guide-radios">
              <Checkbox id="cb-match" checked={this.match} onChange={this.onChangeMatch} />
              <label htmlFor="cb-match">Match</label>

              <Checkbox id="cb-intl" checked={this.intl} onChange={this.onChangeIntl} />
              <label htmlFor="cb-intl">International</label>

              <Checkbox id="cb-vol" checked={this.vol} onChange={this.onChangeVol} />
              <label htmlFor="cb-vol">Volunteer</label>

              <Checkbox id="cb-groups" checked={this.groups} onChange={this.onChangeGroups} />
              <label htmlFor="cb-groups">Groups</label>

              <Checkbox id="cb-analytics" checked={this.analytics} onChange={this.onChangeAnalytics} />
              <label htmlFor="cb-analytics">Analytics</label>

              <Checkbox id="cb-sso" checked={this.sso} onChange={this.onChangeSso} />
              <label htmlFor="cb-sso">SSO</label>

              <Checkbox id="cb-payroll" checked={this.payroll} onChange={this.onChangePayroll} />
              <label htmlFor="cb-payroll">Payroll</label>

              <Checkbox id="cb-madness" checked={this.madness} onChange={this.onChangeMadness} />
              <label htmlFor="cb-madness">Giving Madness</label>
            </div>

            <h2 className="bform-h2">3. Profit 💰</h2>
            {this.price ? (
              <table className="default" style={{width: 'unset'}}>
                <tbody>
                  <tr>
                    <th className="right">{`${numeral(this.empCount).format('0,0')} Emps Total`}</th>
                    <td className="right"><strong>{pricing.format(this.price.total)}</strong></td>
                  </tr>
                  <tr>
                    <th className="right">Match</th>
                    <td className="right">{pricing.format(this.price.match)}</td>
                  </tr>
                  <tr>
                    <th className="right">International</th>
                    <td className="right">{pricing.format(this.price.intl)}</td>
                  </tr>
                  <tr>
                    <th className="right">Volunteering</th>
                    <td className="right">{pricing.format(this.price.vol)}</td>
                  </tr>
                  <tr>
                    <th className="right">Groups</th>
                    <td className="right">{pricing.format(this.price.groups)}</td>
                  </tr>
                  <tr>
                    <th className="right">Analytics</th>
                    <td className="right">{pricing.format(this.price.analytics)}</td>
                  </tr>
                  <tr>
                    <th className="right">SSO</th>
                    <td className="right">{pricing.format(this.price.sso)}</td>
                  </tr>
                  <tr>
                    <th className="right">Payroll</th>
                    <td className="right">{pricing.format(this.price.payroll)}</td>
                  </tr>
                  <tr>
                    <th className="right">Giving Madness</th>
                    <td className="right">{pricing.format(this.price.madness)}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              'Contact Us'
            )}
          </div>
          <br /><br /><br /><br /><br /><br />

          {!!scriptStr && (<>
            <h2 className="bform-h2">Website Script</h2>
            <textarea className="page-bs-pricing-script" disabled defaultValue={scriptStr} />
          </>)}

          <br /><br /><br /><br /><br /><br />
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  query: RoutingSlx.query(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstagePagesPricing);
