import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import ToastAx           from 'app/actions/toast';
import MillieApi         from 'app/apis/millie';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'PAGE_SET_NEW_PW_LOAD',
  GET_EV: 'PAGE_SET_NEW_PW_GET_EV',
  SUBMIT: 'PAGE_SET_NEW_PW_SUBMIT',
};

const Ax = {

  load: (location) => (dispatch, getState) => {
    const promise = dispatch(Ax.getEv(location.params.evId));
    return dispatch({type: Types.LOAD, promise});
  },

  getEv: (id) => {
    const promise = MillieApi.emailVerificationsGet(id);
    return {type: Types.GET_EV, promise, _entities: ['emailVerification']};
  },

  submit: (evId, pw, pw2) => (dispatch, getState) => {
    const promise = MillieApi.emailVerificationsSetNewPw(evId, pw, pw2);
    promise.then(() => {
      dispatch(ToastAx.success('Your password has been updated. You can use it to sign in now.'));
      history.push(paths.login());
    });
    return dispatch({type: Types.SUBMIT, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  submitValidations: null,
  submitPending: false,
  submitError: false,

};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.SUBMIT}_PENDING`]: (state, action) => {
    return {...state,
      submitValidations: null,
      submitPending: true,
      submitError: false,
    };
  },
  [`${Types.SUBMIT}_RESOLVED`]: (state, action) => {
    return {...state,
      submitPending: false,
    };
  },
  [`${Types.SUBMIT}_REJECTED`]: (state, action) => {
    return {...state,
      submitPending: false,
      submitError: true,
      submitValidations: _.get(action, 'error.response.data.error.validations'),
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selSubmitValidations = state => state.pageSetNewPw.submitValidations;
  const selSubmitPending     = state => state.pageSetNewPw.submitPending;
  const selSubmitError       = state => state.pageSetNewPw.submitError;

  const selEv = createSelector(
    [RoutingSlx.params, EntitiesSlx.emailVerifications],
    (params, evMap) => {
      return evMap[params.evId] || null;
    }
  );

  return {
    ev: selEv,
    submitValidations: selSubmitValidations,
    submitPending: selSubmitPending,
    submitError: selSubmitError,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
