const millieEnv = process.env.MILLIE_ENV || 'production';

const defaults = {
  stripePublicKey: process.env.STRIPE_PUBLIC_KEY,
  cdnBaseUrl: process.env.CDN_BASE_URL,
  givingaBaseUrl: 'https://test.givinga.com/api/v1',
  plaidPublicKey: process.env.PLAID_PUBLIC_KEY,
  plaidEnv: process.env.PLAID_ENV,
  gcpApiKeyBrowser: process.env.GCP_API_KEY_BROWSER,
  millieEnv,
  slackAppId: process.env.SLACK_APP_ID,
  slackClientId: process.env.SLACK_CLIENT_ID,
  systemEventsCompanyId: process.env.SYSTEM_EVENTS_COMPANY_ID,
  sentryDsnFrontend: process.env.SENTRY_DSN_FRONTEND,
  defaultDaf: process.env.DEFAULT_DAF,
  stripeDashboardUrl: 'https://dashboard.stripe.com/test',
  stripeProductIds: {
    packageMadness:    'prod_KxpN0zDnucAAbe',
    packageImpact:     'prod_KxpRfTLAvtLX52',
    packageCommunity:  'prod_KxpUbrRGqJeRhq',
    packageEnterprise: 'prod_KxpVjhYq0ZX3Go',
    addonSso:          'prod_Ky8nRsoM7mwsjF',
    addonPayroll:      'prod_Ky8sA4KObn05Yv',
  },
};

const envOverwrites = {
  development: {
    baseUrl: 'http://localhost:1113',
    apiBaseUrl: 'http://localhost:1113/api',
    cookieDomain: null,
    rootDomain: 'millie.localhost',
    siteDomain: 'www.millie.localhost',
    appDomain: 'app.millie.localhost',
  },
  staging: {
    baseUrl: 'https://app.millie-staging.com',
    apiBaseUrl: 'https://app.millie-staging.com/api',
    cookieDomain: 'millie-staging.com',
    rootDomain: 'millie-staging.com',
    siteDomain: 'www.millie-staging.com',
    appDomain: 'app.millie-staging.com',
  },
  production: {
    baseUrl: 'https://app.milliegiving.com',
    apiBaseUrl: 'https://app.milliegiving.com/api',
    cookieDomain: 'milliegiving.com',
    rootDomain: 'milliegiving.com',
    siteDomain: 'www.milliegiving.com',
    appDomain: 'app.milliegiving.com',
    givingaBaseUrl: 'https://hub.givinga.com/api/v1',
    stripeDashboardUrl: 'https://dashboard.stripe.com',
    stripeProductIds: {
      packageMadness:    'prod_L34CNgDpu7gQjV',
      packageImpact:     'prod_L34DCiNcNR03Th',
      packageCommunity:  'prod_L34DjI8zrKIzWt',
      packageEnterprise: 'prod_L34D5cYm2kW3PF',
      addonSso:          'prod_L34DTIpuByDied',
      addonPayroll:      'prod_L34EfAIDehVsvv',
    },
    gaPropertyId: 'G-6JLB7S29V4',
  },
};

const Config = Object.assign({}, defaults, (envOverwrites[millieEnv] || {}));

module.exports = Config;
