import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import CompaniesAx   from 'app/actions/company-admin/companies';
import CadminApi     from 'app/apis/company-admin';
import Link          from 'app/components/common/link';
import Modal         from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import ModalChannel  from 'app/components/company-admin/slack/modal-channel';
import config        from 'app/config';
import helpers       from 'app/helpers/slack';
import paths         from 'app/paths';
import CadminSlx     from 'app/selectors/company-admin/';

class ModalSlackChannelCompany extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onUnlink = this.onUnlink.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onCreate(slackChannel) {
    const { company, update, idField, getTopic } = this.props;
    return update(company.id, {[idField]: slackChannel.id}).then(() => {
      const topic = getTopic(company);
      if (topic) {
        CadminApi.slackChannelsSetTopic(company.id, slackChannel.id, topic);
      }
    });
  }

  onUnlink(_slackChannelId) {
    const { company, update, idField } = this.props;
    return update(company.id, {[idField]: null});
  }

  renderSubHeader() {
    return null;
    const { campaign } = this.props;
    return (
      <div className="ca-modal-schannel-subheader">
        <img src={`${config.cdnBaseUrl}/${campaign.imgPath}`} />
        {campaign.name}
      </div>
    );
  }

  render() {
    const { company, title, defaultName, idField, isPrivate } = this.props;

    return (
      <ModalChannel
        title={title}
        onClose={this.onClose}
        onCreate={this.onCreate}
        onUnlink={this.onUnlink}
        id={company[idField]}
        subHeader={this.renderSubHeader()}
        defaultName={defaultName(company)}
        isPrivate={isPrivate}
      />
    );
  }

}

ModalSlackChannelCompany.propTypes = {
  onClose: PropTypes.func.isRequired,
  idField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  defaultName: PropTypes.func.isRequired,
  getTopic: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
  update: (companyId, attrs) => dispatch(CompaniesAx.update(companyId, attrs)),
});

const ConnectedModalSlackChannelCompany = connect(stateToProps, dispatchToProps)(ModalSlackChannelCompany)

ConnectedModalSlackChannelCompany.Main = (props) => (
  <ConnectedModalSlackChannelCompany {...props}
    title="Main Channel"
    idField="mainSlackChannelId"
    isPrivate={false}
    defaultName={(company) => `${company.name}-gives`}
    getTopic={(company) => `${config.baseUrl}${paths.companyDashboard(company.companyDashboardId)}`}
  />
);

ConnectedModalSlackChannelCompany.Admin = (props) => (
  <ConnectedModalSlackChannelCompany {...props}
    title="Admin Channel"
    idField="adminSlackChannelId"
    isPrivate={true}
    defaultName={() => 'millie-admins'}
    getTopic={() => 'Review incoming match requests'}
  />
);

export default ConnectedModalSlackChannelCompany;
