import React from 'react';
import { connect } from 'react-redux';

import Meta            from 'app/components/common/meta';
import MainLayout      from 'app/components/layout/main-layout';
import Header          from 'app/components/nonprofit-profile/header';
import Profile         from 'app/components/nonprofit-profile/profile';
import config          from 'app/config';
import paths           from 'app/paths';
import AuthSlx         from 'app/selectors/auth';
import PageSlx         from 'app/selectors/page-nonprofit-profile';

// https://schema.org/USNonprofitType
const subsectionSchemaMap = {
  '501(c)(1)': 'Nonprofit501c1',
  '501(c)(2)': 'Nonprofit501c2',
  '501(c)(3)': 'Nonprofit501c3',
  '501(c)(4)': 'Nonprofit501c4',
  '501(c)(5)': 'Nonprofit501c5',
  '501(c)(6)': 'Nonprofit501c6',
  '501(c)(7)': 'Nonprofit501c7',
  '501(c)(8)': 'Nonprofit501c8',
  '501(c)(9)': 'Nonprofit501c9',
  '501(c)(10)': 'Nonprofit501c10',
  '501(c)(11)': 'Nonprofit501c11',
  '501(c)(12)': 'Nonprofit501c12',
  '501(c)(13)': 'Nonprofit501c13',
  '501(c)(14)': 'Nonprofit501c14',
  '501(c)(15)': 'Nonprofit501c15',
  '501(c)(16)': 'Nonprofit501c16',
  '501(c)(17)': 'Nonprofit501c17',
  '501(c)(18)': 'Nonprofit501c18',
  '501(c)(19)': 'Nonprofit501c19',
  '501(c)(20)': 'Nonprofit501c20',
  '501(c)(21)': 'Nonprofit501c21',
  '501(c)(23)': 'Nonprofit501c23',
  '501(c)(25)': 'Nonprofit501c25',
  '501(c)(26)': 'Nonprofit501c26',
  '501(c)(27)': 'Nonprofit501c27',
  '501(d)': 'Nonprofit501d',
  '501(e)': 'Nonprofit501e',
  '501(f)': 'Nonprofit501f',
  '501(k)': 'Nonprofit501k',
};

class PageNonprofitProfile extends React.PureComponent {

  get showClaimCta() {
    const {nonprofit, currentUser} = this.props;
    if (!nonprofit) return false;
    if (nonprofit.claimed) return false;
    if (currentUser?.employment) return false;
    if ((currentUser?.nonprofitAdmins || []).find(na => na.nonprofit?.id === nonprofit.id)) return false;
    return true;
  }

  get schema() {
    const {nonprofit, profile} = this.props;
    if (!nonprofit) return null;
    const img1Url = profile?.img1Path && `${config.cdnBaseUrl}/${profile.img1Path}`;
    const img2Url = profile?.img2Path && `${config.cdnBaseUrl}/${profile.img2Path}`;
    const img3Url = profile?.img3Path && `${config.cdnBaseUrl}/${profile.img3Path}`;
    const images = [img1Url, img2Url, img3Url].filter(u => u);
    const logoUrl = profile?.logoPath && `${config.cdnBaseUrl}/${profile.logoPath}`;
    const addr = nonprofit.address;
    const status = subsectionSchemaMap[nonprofit.subsection] || undefined;
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: nonprofit.name,
      description: nonprofit.mission,
      url: `${config.baseUrl}${paths.nonprofit(nonprofit)}`,
      sameAs: nonprofit.website || undefined,
      image: images.length ? images : undefined,
      logo: logoUrl || undefined,
      foundingDate: nonprofit.formationYear ? `${nonprofit.formationYear}` : undefined,
      nonprofitStatus: status,
      taxID: nonprofit.ein,
      identifier: nonprofit.ein,
    };
    if (addr) {
      obj.address = {
        '@type': 'PostalAddress',
        streetAddress: addr.addressLine1 || undefined,
        addressLocality: addr.city || undefined,
        addressRegion: addr.stateName || addr.state || undefined,
        addressCountry: addr.country || undefined,
        postalCode: addr.postalCode || undefined,
      };
    }
    return obj;
  }

  render() {
    const {nonprofit, profile} = this.props;
    return (
      <MainLayout>
        {nonprofit && (
          <Meta
            title={nonprofit.name}
            ogTitle={nonprofit.name}
            ogDesc={nonprofit.mission}
            ogImgUrl={nonprofit.imgUrl}
            schema={this.schema}
          />
        )}
        {/* <pre>{JSON.stringify(this.schema, null, 4)}</pre> */}
        <div className="nonprofit-profile-page npp">
          {!!nonprofit && <>
            <Header nonprofit={nonprofit} profile={profile} />
            <Profile nonprofit={nonprofit} profile={profile} showClaimCta={this.showClaimCta} />
          </>}
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  nonprofit: PageSlx.nonprofit(state),
  profile: PageSlx.profile(state),
  currentUser: AuthSlx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageNonprofitProfile);
