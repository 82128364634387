import React from 'react';
import { connect } from 'react-redux';

import Link          from 'app/components/common/link';
import Meta          from 'app/components/common/meta';
import StandardInput from 'app/components/common/standard-input';
import MainLayout    from 'app/components/layout/main-layout';
import {
  EmailVerificationReasons as Reasons,
}                    from 'app/constants';
import Duck          from 'app/ducks/page-set-new-pw';
import paths         from 'app/paths';
import RoutingSlx    from 'app/selectors/routing';

class PageSetNewPw extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      pw: '',
      pw2: '',
    };

    this.onChangePw = this.onChangePw.bind(this);
    this.onChangePw2 = this.onChangePw2.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  onChangePw(event) {
    const pw = event.target.value;
    this.setState({pw});
  }
  onChangePw2(event) {
    const pw2 = event.target.value;
    this.setState({pw2});
  }

  onClickSubmit() {
    this.props.submit(this.props.ev?.id, this.state.pw, this.state.pw2);
  }

  get canSubmit() {
    if (!this.state.pw.trim()) return false;
    if (!this.state.pw2.trim()) return false;
    if (this.props.pending) return false;
    return true;
  }

  get errMsg() {
    const {ev} = this.props;
    if (!ev) return null;
    if (ev.reason !== Reasons.RESET_PW) return 'This link is invalid';
    if (ev.redeemed) return 'This password reset link has already been used.';
    if (ev.expired)  return 'This password reset link has expired.';
  }

  renderError() {
    if (!this.errMsg) return null;
    return (<>
      <p className="page-forgot-pw-err-msg">{`Oops! ${this.errMsg}`}</p>
      <Link href={paths.forgotPassword()} className="btn small blue">Try Again</Link>
    </>);
  }

  renderForm() {
    const {pw, pw2} = this.state;
    const {ev, pending, validations, submitError} = this.props;
    if (!ev) return null;
    if (this.errMsg) return null;

    return (<>
      <StandardInput label="New Password" name="password" type="password" validations={validations} onChange={this.onChangePw} value={pw} />
      <StandardInput label="Confirm New Password" name="password2" type="password" validations={validations} onChange={this.onChangePw2} value={pw2} />
      <button onClick={this.onClickSubmit} disabled={!this.canSubmit} className="btn blue form-box-submit-btn">{pending ? 'Setting password...' : 'Set New Password'}</button>
      {!!submitError && <p className="page-forgot-pw-err-msg">Oops! Something went wrong.</p>}
    </>);
  }

  render() {
    const {pw, pw2} = this.state;
    const {ev} = this.props;

    return (
      <MainLayout>
        <Meta title="New Password" />
        <div className="page-forgot-pw widther">
          <div className="form-box">

            <div className="form-box-header">
              <h1>Set New Password</h1>
            </div>

            <div className="form-box-form">
              {this.renderError()}
              {this.renderForm()}
            </div>

          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  query: RoutingSlx.query(state),
  ev: Duck.Slx.ev(state),
  validations: Duck.Slx.submitValidations(state),
  pending: Duck.Slx.submitPending(state),
  submitError: Duck.Slx.submitError(state),
});

const dispatchToProps = (dispatch) => ({
  submit: (evId, pw, pw2) => dispatch(Duck.Ax.submit(evId, pw, pw2)),
});

export default connect(stateToProps, dispatchToProps)(PageSetNewPw);
