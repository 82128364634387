import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Meta           from 'app/components/common/meta';
import CadminLayout   from 'app/components/company-admin/layout/';
import PageLoading    from 'app/components/layout/page-loading';
import {
  Features,
}                     from 'app/constants';
import CadminSlx      from 'app/selectors/company-admin/';

class PageCadminSubscription extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { company } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-subx" company={company} activeItem="subscription">
        <Meta title="Subscription | Millie" />
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">
              Subscription
            </h1>
            <div className="ca-box-header-controls"></div>
          </div>
          <div className="ca-box-body">
            <div className="ca-subx-disclaim">
              <p>All Millie programs run on an annual basis. An invoice is sent at the beginning of each billing period.</p>
              <p>If you would like to upgrade, downgrade, or cancel your plan, please reach out to <a href="mailto:team@milliegiving.com">team@milliegiving.com</a>.</p>
            </div>
            <h2 className="ca-subx-subheading">Features</h2>
            <div className="ca-subx-features">
              {Object.values(Features).map((feature) => {
                const isOn = !!company.features[feature];
                return (
                  <div key={feature} className={`ca-subx-feature ${isOn ? 'on' : 'off'}`}>{feature}</div>
                );
              })}
            </div>
            <h2 className="ca-subx-subheading">Employees</h2>
            <p>Allowed: <strong>{numeral(company.saasEmployeeCap).format('0,0')}</strong></p>
            <p>Used: <strong>{numeral(company.employeeCount).format('0,0')}</strong></p>
            <h2 className="ca-subx-subheading">Billing Portal</h2>
            <div className="ca-subx-portal">
              <span className="ca-subx-portal-label">Change billing email, billing address, and view all invoices</span>
              <a className="btn blue" href={`/api/company-admin/${company.id}/subscription/go-to-portal`} target="_blank">View Billing Portal</a>
            </div>
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminSubscription);
